<template>
  <div class="flex flex-wrap gap-4">
    <slot name="before" />
    <AButton
      label="Order a Custom Assembly"
      to="/contact/rfq"
      color="blue"
      icon="i-carbon-task-tools"
    />
    <AButton
      label="Contact Us"
      to="/contact"
      color="cyan"
      icon="i-carbon-chat"
    />
    <AButton
      label="Catalogs & Credentials"
      to="/resources"
      color="gray"
      icon="i-carbon-document"
    />
    <slot name="after" />
  </div>
</template>
